import { BehaviorSubject } from 'rxjs';

import { handleResponse } from '../utils/handleResponse';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser')),
);

const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  };

  return fetch(
    `${process.env.REACT_APP_AUTH_BASE_PATH}${process.env.REACT_APP_AUTH_LOGIN_PATH}`,
    requestOptions,
  )
    .then(handleResponse)
    .then((userResponse) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(userResponse));
      currentUserSubject.next(userResponse);
      return userResponse;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

export default authenticationService;
