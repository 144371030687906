import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.scss';
import { Navigation } from './components/Navigation';
import { FAQ } from './pages/FAQ';
import { Home } from './pages/Home';
import Login from './pages/Login';
import { Plants } from './pages/Plants';

import authenticationService from './services/AuthenticationService';

class App extends React.Component<{}, { currentUser: null }> {
  state = { currentUser: null };
  constructor(props: any) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount = () => {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x }),
    );
  };

  render(): JSX.Element {
    return (
      <Router>
        <div className="App">
          <header>
            <Navigation initVisible={false}></Navigation>
          </header>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <main>
            <Switch>
              <Route path="/faq">
                <FAQ></FAQ>
              </Route>
              <Route path="/login">
                <Login></Login>
              </Route>
              <Route path="/plants">
                <Plants></Plants>
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
          <footer>
            This piece of software is made with ❤︎ in Berlin by{' '}
            <a href="https://janfanslau.com" target="_blank" rel="noreferrer">
              me!
            </a>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
