import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import authenticationService from '../services/AuthenticationService';
import './Navigation.scss';

export const Navigation: FunctionComponent<{ initVisible?: Boolean }> = ({
  initVisible = false,
}) => {
  const [visible, setVisible] = useState(initVisible);
  const currentUser = authenticationService.currentUserValue;
  return (
    <>
      <nav className={visible ? 'show' : ''}>
        <div className="nav-content">
          <Link to="/" onClick={() => setVisible(false)} className="logo">
            <span style={{ fontSize: 20 }}>🌱</span>
          </Link>

          <div className="nav-icon" onClick={() => setVisible(!visible)}>
            <div className="bar one"></div>
            <div className="bar two"></div>
          </div>

          <div className="nav-links">
            <Link to="/" onClick={() => setVisible(false)}>
              Home
            </Link>
            <Link to="/plants" onClick={() => setVisible(false)}>
              Plants
            </Link>
            <Link to="/faq" onClick={() => setVisible(false)}>
              FAQ
            </Link>
            {!currentUser && (
              <Link to="/login" onClick={() => setVisible(false)}>
                Login
              </Link>
            )}
            {currentUser && (
              <div>
                <Link
                  to="/"
                  onClick={() => {
                    setVisible(false);
                    authenticationService.logout();
                  }}
                >
                  Logout
                </Link>
              </div>
            )}
          </div>
          {currentUser && (
            <Gravatar email={currentUser.user.email} className="user-icon" />
          )}
        </div>
      </nav>
    </>
  );
};
