import authenticationService from '../services/AuthenticationService';

export function authHeader():HeadersInit {
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return { Authorization: `${currentUser.token}` };
  } else {
    return {};
  }
}

export function appendAuthHeader(headers:Headers):Headers {
	const currentUser = authenticationService.currentUserValue;
  	if (currentUser && currentUser.token) {
			headers.append('Authorization', currentUser.token)
		}
		return headers;
}
