import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import ReactHighcharts from 'react-highcharts';
import moment from 'moment';
import { mapIt } from '../utils/math';

class ChartView extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.series = [
      {
        name: 'Moisture',
        data: [],
      },
      {
        name: 'Water',
        data: [],
      },
    ];

    this.xAxis = {
      categories: [],
    };

    this.props.chartData.forEach((item) => {
      this.series[0].data.push(item.hygro);
      this.series[1].data.push(Math.round(item.waterLevel));
      this.xAxis.categories.push(moment(item.createdAt).format('hh:mm'));
    });
  }

  render() {
    const config = {
      // chart: {
      //   type: 'line',
      //   height: 200,
      // },
      // xAxis: this.xAxis,

      series: this.series,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                height: 200,
              },
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
              xAxis: this.xAxis,
              yAxis: {
                plotLines: [
                  {
                    // mark the hygro value (green)
                    color: 'rgba(92, 191, 0, 0.5)',
                    width: 1,
                    value: this.props.wateringTrigger,
                  },
                  {
                    // mark the water level value (blue)
                    color: 'rgba(74, 144, 226, 0.5)',
                    width: 1,
                    value: 100,
                  },
                ],

                labels: {
                  align: 'left',
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
      colors: [
        '#5CBF00',
        '#4A90E2',
        '#f45b5b',
        '#7798BF',
        '#aaeeee',
        '#ff0066',
        '#eeaaee',
        '#55BF3B',
        '#DF5353',
        '#7798BF',
        '#aaeeee',
      ],
      chart: {
        backgroundColor: 'none',
        style: {
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        plotBorderColor: '#606063',
      },
      title: {
        style: {
          opacity: 0,
          color: '#E0E0E3',
          textTransform: 'uppercase',
          fontSize: '20px',
        },
      },
      subtitle: {
        style: {
          color: '#E0E0E3',
          textTransform: 'uppercase',
        },
      },
      xAxis: {
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3',
          },
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
          style: {
            color: '#A0A0A3',
          },
        },
        categories: this.xAxis.categories,
      },
      yAxis: {
        gridLineColor: '#707073',
        labels: {
          style: {
            color: '#E0E0E3',
          },
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
        title: {
          style: {
            color: '#A0A0A3',
          },
        },
      },

      tooltip: {
        headerFormat: '<small>{point.key}</small><br/>',
        pointFormat:
          '<span style="color: {series.color}">{series.name}: <b>{point.y} %</b>',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
          color: '#F0F0F0',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            color: '#B0B0B3',
          },
          marker: {
            lineColor: '#333',
          },
        },
        boxplot: {
          fillColor: '#505053',
        },
        candlestick: {
          lineColor: 'white',
        },
        errorbar: {
          color: 'white',
        },
      },
      legend: {
        itemStyle: {
          color: '#E0E0E3',
        },
        itemHoverStyle: {
          color: '#FFF',
        },
        itemHiddenStyle: {
          color: '#606063',
        },
      },
      credits: {
        style: {
          color: '#666',
        },
      },
      labels: {
        style: {
          color: '#707073',
        },
      },

      drilldown: {
        activeAxisLabelStyle: {
          color: '#F0F0F3',
        },
        activeDataLabelStyle: {
          color: '#F0F0F3',
        },
      },

      navigation: {
        buttonOptions: {
          symbolStroke: '#DDDDDD',
          theme: {
            fill: '#505053',
          },
        },
      },

      // scroll charts
      rangeSelector: {
        buttonTheme: {
          fill: '#505053',
          stroke: '#000000',
          style: {
            color: '#CCC',
          },
          states: {
            hover: {
              fill: '#707073',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
            select: {
              fill: '#000003',
              stroke: '#000000',
              style: {
                color: 'white',
              },
            },
          },
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
          backgroundColor: '#333',
          color: 'silver',
        },
        labelStyle: {
          color: 'silver',
        },
      },

      navigator: {
        handles: {
          backgroundColor: '#666',
          borderColor: '#AAA',
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
          color: '#7798BF',
          lineColor: '#A6C7ED',
        },
        xAxis: {
          gridLineColor: '#505053',
        },
      },

      scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043',
      },

      // special colors for some of the
      legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      background2: '#505053',
      dataLabelsColor: '#B0B0B3',
      textColor: '#C0C0C0',
      contrastTextColor: '#F0F0F3',
      maskColor: 'rgba(255,255,255,0.3)',
    };

    return (
      <HighchartsReact
        options={config}
        highcharts={Highcharts}
      ></HighchartsReact>
    );
  }
}

export default ChartView;
