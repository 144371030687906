import React, { useState } from 'react';
import authenticationService from '../services/AuthenticationService';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import './login.scss';
function Login() {
  const [error, setError] = useState({ message: '', success: false });
  const [isLoaded, setIsLoaded] = useState(false);
  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  let history = useHistory();

  return (
    <>
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.username) {
            errors.username = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoaded(false);
          var { username, password } = values;
          authenticationService.login(username, password).then(
            (user) => {
              setIsLoaded(true);
              console.log(user);
              history.push('/plants');
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
              console.log('Error', error);
            },
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className="loginForm">
            <form onSubmit={handleSubmit} className="loginForm-form">
              <div className="loginForm-title">Login</div>
              <div className="loginForm-mainError">{error.message}</div>
              <div className="loginForm-section">
                <label className="loginForm-label">Username</label>
                <input
                  className="loginForm-input"
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
                <div className="loginForm-error">
                  {errors.username && touched.username && errors.username}
                </div>
              </div>
              <div className="loginForm-section">
                <label className="loginForm-label">Password</label>
                <input
                  className="loginForm-input"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <div className="loginForm-error">
                  {errors.password && touched.password && errors.password}
                </div>
              </div>
              <button
                className="loginForm-button"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
}
export default Login;
