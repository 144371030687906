import authenticationService from '../services/AuthenticationService';

export function handleResponse(response, history) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        if (data.message !== 'Authentication failed.') {
          history.push('/login');
        }
      }
      data.status = response.status;
      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
