import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authHeader } from '../utils/authHeader';
import { handleResponse } from '../utils/handleResponse';
import PlantItem from './PlantItem';

function PlantItemList() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  let history = useHistory();
  useEffect(() => {
    var requestOptions: RequestInit = {
      method: 'GET',
      headers: authHeader(),
    };

    fetch('/api/app/soggy/plant', requestOptions)
      .then((response) => {
        return handleResponse(response, history);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.plants);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  }, [history]);

  if (error) {
    return <div>Error: {error}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <ul style={{ margin: '0', padding: '0' }}>
        {items.map((item, index) => (
          <PlantItem item={item} key={index} />
        ))}
      </ul>
    );
  }
}

export default PlantItemList;
