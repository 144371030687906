import React from 'react';
import { mapIt } from '../utils/math';
import moment from 'moment';
import './PlantItem.scss';
import ChartView from './ChartView';

function PlantItem(props: any) {
  const updatedAt = moment(props.item.updatedAt).fromNow();
  if (!props.item.lastData) {
    props.item.lastData = { hygro: '', waterLevel: '' };
  }
  return (
    <div className="plant">
      <h1 className="plant-title">{props.item.name}</h1>
      <div className="plant-location">{props.item.location}</div>
      <div className="plant-chart">
        <ChartView
          chartData={props.item.chartData}
          wateringTrigger={props.item.settings.wateringTriggerValue}
        />
      </div>
      <div className="plant-values">
        <div className="plant-moisture">
          {props.item.lastData.hygro}% (
          {props.item.settings.wateringTriggerValue}
          %)
        </div>
        <div className="plant-waterlevel">
          {Math.round(props.item.lastData.waterLevel)}%{' '}
        </div>
        <div className="plant-lastWatering">
          {props.item.lastWateringAt
            ? `last watering ${moment(props.item.lastWateringAt).fromNow()}`
            : ''}
        </div>
        <div className="plant-lastUpdate"> {updatedAt}</div>
      </div>
    </div>
  );
}

export default PlantItem;
