import React from 'react';
import PlantItemList from '../components/PlantItemList';

export function Plants() {
  return (
    <>
      <h1>Plants</h1>
      <PlantItemList></PlantItemList>
    </>
  );
}
