import React from 'react';

export function Home() {
  return (
    <>
      <h1>Welcome to Soggy</h1>
      <h2>The selfwatering plant system</h2>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam
        dolorum culpa excepturi odit ad reiciendis itaque fuga expedita eveniet
        fugit totam veniam, suscipit, quasi voluptates architecto sint?
        Recusandae, laborum ullam!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam
        dolorum culpa excepturi odit ad reiciendis itaque fuga expedita eveniet
        fugit totam veniam, suscipit, quasi voluptates architecto sint?
        Recusandae, laborum ullam!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam
        dolorum culpa excepturi odit ad reiciendis itaque fuga expedita eveniet
        fugit totam veniam, suscipit, quasi voluptates architecto sint?
        Recusandae, laborum ullam!
      </p>
    </>
  );
}
